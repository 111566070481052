import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import BeabaThematiquesService from 'services/BeabaThematiquesService';

export const initialState = {
  loadingBeabaThematiquesDetails: false,
  loadingBeabaThematiquesList: false,
  loadingBeabaThematiquesTypesList: false,

  messageBeabaThematiques: '',

  beabaThematiquesCreatedId: '',
  beabaThematiquesUpdatedId: '',
  beabaThematiquesDeleted: false,

  beabaThematiquesDetails: [],
  listBeabaThematiquesApi: [],
}

export const createBeabaThematiques = createAsyncThunk('beabaThematiques/createBeabaThematiques', async (beabaThematiquesData, { rejectWithValue }) => {
  const { libelle, id_beaba_thematique, beaba_thematique_picto } = beabaThematiquesData
  try {
    const response = await BeabaThematiquesService.createBeabaThematiques({ libelle, id_beaba_thematique })
    if (beaba_thematique_picto) {
      const tempId = response.data.id
      const formData = new FormData();
      formData.append('beaba_thematique_picto', beaba_thematique_picto);
      await BeabaThematiquesService.addBeabaThematiquesPictoById(tempId, formData)
    }
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response?.data?.message || 'Error')
  }
})

export const getBeabaThematiquesList = createAsyncThunk('beabaThematiques/getBeabaThematiquesList', async (_, { rejectWithValue }) => {
  try {
    const response = await BeabaThematiquesService.getBeabaThematiquesList();
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response?.data?.message || 'Error')
  }
})

// ID
export const getBeabaThematiquesById = createAsyncThunk('beabaThematiques/getBeabaThematiquesById', async (beabaThematiquesId, { rejectWithValue }) => {
  try {
    const response = await BeabaThematiquesService.getBeabaThematiquesById(beabaThematiquesId);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response?.data?.message || 'Error')
  }
})

export const updateBeabaThematiquesById = createAsyncThunk('beabaThematiques/updateBeabaThematiquesById', async (beabaThematiquesData, { rejectWithValue }) => {
  try {
    const { libelle, id_beaba_thematique, beaba_thematique_picto } = beabaThematiquesData
    const response = await BeabaThematiquesService.updateBeabaThematiquesById(id_beaba_thematique, { libelle, id_beaba_thematique });
    if (beaba_thematique_picto) {
      const formData = new FormData();
      formData.append('beaba_thematique_picto', beaba_thematique_picto);
      await BeabaThematiquesService.deleteBeabaThematiquesPictoById(id_beaba_thematique);
      await BeabaThematiquesService.addBeabaThematiquesPictoById(id_beaba_thematique, formData);
    }
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response?.data?.message || 'Error')
  }
})

export const deleteBeabaThematiquesById = createAsyncThunk('beabaThematiques/deleteBeabaThematiquesById', async (beabaThematiquesId, { rejectWithValue }) => {
  try {
    const response = await BeabaThematiquesService.deleteBeabaThematiquesById(beabaThematiquesId);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response?.data?.message || 'Error')
  }
})

export const beabaThematiquesSlice = createSlice({
  name: 'beabaThematiques',
  initialState,
  reducers: {
    resetMessageBeabaThematiques: (state) => {
      state.messageBeabaThematiques = ''
    },

    resetBeabaThematiquesCreatedId: (state) => {
      state.beabaThematiquesCreatedId = ''
    },
    resetBeabaThematiquesUpdatedId: (state) => {
      state.beabaThematiquesUpdatedId = ''
    },
    resetBeabaThematiquesDeleted: (state) => {
      state.beabaThematiquesDeleted = false
    },

    resetBeabaThematiquesDetails: (state) => {
      state.beabaThematiquesDetails = []
    }
  },
  extraReducers: (builder) => {
    builder
      // createBeabaThematiques
      .addCase(createBeabaThematiques.pending, (state) => {
        state.loadingBeabaThematiquesDetails = true
      })
      .addCase(createBeabaThematiques.fulfilled, (state, action) => {
        state.loadingBeabaThematiquesDetails = false
        state.messageBeabaThematiques = "Thématique créée avec succès"
        state.beabaThematiquesCreatedId = action.payload.id
      })
      .addCase(createBeabaThematiques.rejected, (state, action) => {
        state.loadingBeabaThematiquesDetails = false
      })

      // getBeabaThematiquesList
      .addCase(getBeabaThematiquesList.pending, (state) => {
        state.loadingBeabaThematiquesList = true
      })
      .addCase(getBeabaThematiquesList.fulfilled, (state, action) => {
        state.loadingBeabaThematiquesList = false
        state.listBeabaThematiquesApi = action.payload
      })
      .addCase(getBeabaThematiquesList.rejected, (state, action) => {
        state.loadingBeabaThematiquesList = false
        state.messageBeabaThematiques = action.payload.message
      })

      // getBeabaThematiquesById
      .addCase(getBeabaThematiquesById.pending, (state) => {
        state.loadingBeabaThematiquesDetails = true
      })
      .addCase(getBeabaThematiquesById.fulfilled, (state, action) => {
        state.loadingBeabaThematiquesDetails = false
        state.beabaThematiquesDetails = action.payload
      })
      .addCase(getBeabaThematiquesById.rejected, (state, action) => {
        state.loadingBeabaThematiquesDetails = false
        state.messageBeabaThematiques = action.payload.message
      })

      // updateBeabaThematiquesById
      .addCase(updateBeabaThematiquesById.pending, (state) => {
        state.loadingBeabaThematiquesDetails = true
      })
      .addCase(updateBeabaThematiquesById.fulfilled, (state, action) => {
        state.loadingBeabaThematiquesDetails = false
        state.messageBeabaThematiques = "Thématique modifiée avec succès"
        state.beabaThematiquesUpdatedId = action.payload.id
      })
      .addCase(updateBeabaThematiquesById.rejected, (state, action) => {
        state.loadingBeabaThematiquesDetails = false
      })

      // deleteBeabaThematiquesById
      .addCase(deleteBeabaThematiquesById.pending, (state) => {
        // 
      })
      .addCase(deleteBeabaThematiquesById.fulfilled, (state, action) => {
        state.messageBeabaThematiques = "Thématique supprimée avec succès"
        state.beabaThematiquesDeleted = true
      })
      .addCase(deleteBeabaThematiquesById.rejected, (state, action) => {
        state.messageBeabaThematiques = action.payload.message
      })
  },
})

export const { resetMessageBeabaThematiques, resetBeabaThematiquesCreatedId, resetBeabaThematiquesUpdatedId, resetBeabaThematiquesDeleted, resetBeabaThematiquesDetails } = beabaThematiquesSlice.actions

export default beabaThematiquesSlice.reducer