import fetch from "auth/FetchInterceptor";

const BeabaThematiquesService = {};

BeabaThematiquesService.createBeabaThematiques = function (beabaThematiquesData) {
  return fetch({
    url: "/beaba_thematiques",
    method: "post",
    data: beabaThematiquesData,
  });
};

BeabaThematiquesService.getBeabaThematiquesList = function () {
  return fetch({
    url: "/beaba_thematiques",
    method: "get",
    allowError422: true
  });
};

// ID
BeabaThematiquesService.getBeabaThematiquesById = function (beabaThematiquesId) {
  return fetch({
    url: "/beaba_thematiques/" + beabaThematiquesId,
    method: "get",
  });
};

BeabaThematiquesService.updateBeabaThematiquesById = function (beabaThematiquesId, beabaThematiquesData) {
  return fetch({
    url: "/beaba_thematiques/" + beabaThematiquesId,
    method: "put",
    data: beabaThematiquesData,
  });
};

BeabaThematiquesService.deleteBeabaThematiquesById = function (beabaThematiquesId) {
  return fetch({
    url: "/beaba_thematiques/" + beabaThematiquesId,
    method: "delete",
  });
};

// Images
BeabaThematiquesService.addBeabaThematiquesPictoById = function (beabaThematiquesId, beabaThematiquesData) {
  return fetch({
    url: "/beaba_thematiques/" + beabaThematiquesId + "/images",
    method: "post",
    data: beabaThematiquesData,
  });
};

BeabaThematiquesService.deleteBeabaThematiquesPictoById = function (beabaThematiquesId) {
  return fetch({
    url: "/beaba_thematiques/" + beabaThematiquesId + "/images",
    method: "delete",
  });
};

export default BeabaThematiquesService;
