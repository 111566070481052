import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import actions from './slices/actionsSlice'
import auth from './slices/authSlice'
import beabas from './slices/beabasSlice'
import etapes from './slices/etapesSlice'
import oms from './slices/omsSlice'
import travaux from './slices/travauxSlice'
import beabaThematiques from './slices/beabaThematiquesSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,        
        auth,
        actions,
        beabas,
        etapes,
        oms,
        travaux,
        beabaThematiques,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
